import {PolymerElement, html} from '@polymer/polymer/polymer-element.js';
import '@polymer/polymer/lib/elements/dom-repeat.js';

import template from './watcher.html';
import css from './watcher.css';
import config from '../../config/config.json';

/**
 * @polymer
 * @extends HTMLElement
 */
export class TtcWatcher extends PolymerElement {
  static get properties() {
    return {
      primary: {
        type: String,
        value: 'IRON 4090',
      },
      watchedItems: {
        type: Array,
        value: function() {
          return [
            {
              mnemonic: 'PWBVTLM',
              threshold: 40.4,
              value: 24.2,
              subsystem: 'Attitude',
              status: 'normal',
              unit: 'Volts',
            },
            {
              mnemonic: 'PWBVPCDUST',
              threshold: 37.2,
              value: 21.3,
              subsystem: 'Attitude',
              status: 'caution',
              unit: 'Volts',
            },
            {
              mnemonic: 'PWBVSCPST',
              threshold: 37.6,
              value: 21.1,
              subsystem: 'Attitude',
              status: 'alert',
              unit: 'Volts',
            },
            {
              mnemonic: 'PWBTST',
              threshold: 39.1,
              value: 22.7,
              subsystem: 'Attitude',
              status: 'alert',
              unit: 'Volts',
            },
          ];
        },
      },
    };
  }

  static get template() {
    return html([
      `
        <style include="astro-css">
          ${css}
        </style> 
        ${template}`,
    ]);
  }

  constructor() {
    super();
  }

  connectedCallback() {
    super.connectedCallback();
    this._getWatcherData();
  }

  disconnectedCallback() {
    super.disconnectedCallback();
  }

  _getWatcherData() {
    // Work-in-Progress - dynamically updating watcher data
    // Note: the data from this service is not currently used to update the template
    const request = new XMLHttpRequest();
    let rawData = [];
    const mnemonic = [];
    const actual = [];
    let done = false;

    // Get mnemonic and value data from the service
    request.open(
        'GET',
        config.dataServers.mnemonics,
        true
    );

    request.send();
    request.onreadystatechange = ()=> {
      rawData = request.responseText;
      if (rawData.length > 0 && !done) {
        const parsedData = JSON.parse(rawData);
        done = true;
        for (let i = 0; i < parsedData.length; i++) {
          mnemonic.push(parsedData[i].mnemonic);
          actual.push(parsedData[i].value);
        }
      }
    };
  }
  selectMnemonic(e) {
    this.dispatchEvent( new CustomEvent('mnemonicSelected', {
      detail: {
        message: e.model.item.mnemonic,
      },
      bubbles: true, composed: true}));
    e.stopPropagation();
    return false;
  }
  showToolTip(e) {
    const tooltipModel = {
      label: e.model.item.mnemonic,
      actualValue: `${e.model.item.value} ${e.model.item.unit}`,
      expectedValue: `${e.model.item.threshold} ${e.model.item.unit}`,
      subsystem: e.model.item.subsystem,
      watched: e.model.item.watched,
      hasGraph: e.model.item.hasGraph,
    };
    this.dispatchEvent(
        new CustomEvent('showTooltip', {
          detail: {
            target: e.target,
            data: tooltipModel,
          },
          composed: true,
          bubbles: true,
        })
    );
    e.stopPropagation();
    return false;
  }
}
customElements.define('ttc-watcher', TtcWatcher);

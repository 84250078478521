import config from '../config/config.json';
import {html, PolymerElement} from '@polymer/polymer/polymer-element.js';

/* eslint-disable no-unused-vars */
/* Astro Components */
import {RuxClock} from '@astrouxds/rux-clock/rux-clock.js';
import {RuxGlobalStatusBar} from '@astrouxds/rux-global-status-bar/rux-global-status-bar.js';
import {RuxIcon} from '@astrouxds/rux-icon/rux-icon.js';
import {RuxStatus} from '@astrouxds/rux-status/rux-status.js';
import {RuxSegmentedButton} from '@astrouxds/rux-segmented-button/rux-segmented-button.js';
import {RuxSlider} from '@astrouxds/rux-slider/rux-slider.js';

/* TT&C Services Templates and Styles */
import {AppMenu} from './app-menu.js';
import {StatusIndicators} from './status/status-indicators.js';
import {TtcPass} from './pass-plan/pass.js';
import {TtcCommand} from './pass-plan/command.js';
import {Tooltip} from './tooltip/tooltip.js';
/* eslint-enable no-unused-vars */

import template from './astro-app.html';
import css from './astro-app.css';

/* eslint-disable no-unused-vars */
import astroCSS from './astro-css.js';

/*
    TT&C Monitor Components
    Add TT&C Monitor specific components here
*/
import {GrmAlerts} from './alerts/alerts.js';
import {TtcSystemHealth} from './system-health/system-health.js';
import {TtcSubsystems} from './subsystems/subsystems.js';
import {TtcWatcher} from './watcher/watcher.js';
/* eslint-disable no-unused-vars */

/**
 * @polymer
 * @extends HTMLElement
 */
export class AstroApp extends PolymerElement {
  static get template() {
    return html([
      `
      <style include="astro-css">
        ${css}
      </style> 
      ${template}`,
    ]);
  }

  static get properties() {
    return {
      statusData: {
        type: Object,
        value: {
          ucaStatus: {worstStatus: 'standby', numMessages: 45},
          timestamp: 1546641719633,
        },
      },
      systemHealthData: {
        type: Object,
      },
      aos: {
        type: Object,
        value: new Date(),
      },
      los: {
        type: Object,
        value: new Date(),
      },
      tooltipData: {
        type: Object,
        value: () => {
          return {};
        },
      },
      passState: {
        type: String,
        value: 'prepass',
      },
      passPlanSteps: {
        type: Array,
        value: [
          {
            _id: 'step-0001',
            label: 'HM1114RPW',
            type: 'single-step',
            expectedValue: 'on',
            actualValue: 'on',
            subsystem: 'Attitude',
            complete: false,
            hasGraph: false,
            watched: false,
          },
          {
            _id: 'step-0002',
            label: 'VRCPEDACEN',
            type: 'single-step',
            expectedValue: 'enabled',
            actualValue: 'enabled',
            subsystem: 'Attitude',
            complete: false,
            hasGraph: false,
            watched: false,
          },
          {
            _id: 'step-0003',
            label: 'VRHMCRD',
            type: 'single-step',
            expectedValue: 'open',
            actualValue: 'open',
            subsystem: 'Attitude',
            complete: false,
            hasGraph: false,
            watched: true,
          },
          {
            _id: 'step-0004',
            label: 'ADST2S3IN4',
            type: 'single-step',
            expectedValue: '< 1.25 Magnitude',
            actualValue: '1.23 Magnitude',
            subsystem: 'Attitude',
            complete: false,
            hasGraph: true,
            watched: false,
          },
          {
            _id: 'step-0005',
            label: 'Mnemonic',
            type: 'two-part',
            prompts: ['Is "XYZ" selected?', 'Are you receiving telemetry?'],
          },
          {
            _id: 'step-0006',
            label: '81001',
            type: 'multi-step',
            progress: 1,
            playState: 'unplayed',
            expanded: false,
            steps: [
              {
                _id: 'step-0006-1',
                label: 'DTU1L2PW',
                type: 'single-step',
                expectedValue: '1',
                actualValue: '1',
                subsystem: 'Attitude',
                complete: false,
                hasGraph: true,
                watched: true,
              },
              {
                _id: 'step-0006-2',
                label: 'GCEGNXTK',
                type: 'single-step',
                expectedValue: 'sel',
                actualValue: 'sel',
                subsystem: 'Attitude',
                complete: false,
                hasGraph: false,
                watched: false,
              },
              {
                _id: 'step-0006-3',
                label: 'P1ABATI1',
                type: 'single-step',
                expectedValue: 'RGP 1',
                actualValue: 'RGP 1',
                subsystem: 'Attitude',
                complete: false,
                hasGraph: false,
                watched: false,
              },
              {
                _id: 'step-0006-4',
                label: 'OMCPEP28I',
                type: 'single-step',
                expectedValue: '</=60 Amps',
                actualValue: '59 Amps',
                subsystem: 'Attitude',
                complete: false,
                hasGraph: true,
                watched: false,
              },
            ],
          },
          {
            _id: 'step-0007',
            label: 'HM1516RPW',
            type: 'single-step',
            expectedValue: 'on',
            actualValue: 'on',
            subsystem: 'Attitude',
            complete: false,
            hasGraph: false,
            watched: false,
          },
          {
            _id: 'step-0008',
            label: 'VRPS30SCCEN',
            type: 'single-step',
            expectedValue: 'enabled',
            actualValue: 'enabled',
            subsystem: 'Attitude',
            complete: false,
            hasGraph: false,
            watched: false,
          },
          {
            _id: 'step-0009',
            label: 'OMBTSEL0',
            type: 'single-step',
            expectedValue: 'open',
            actualValue: 'open',
            subsystem: 'Attitude',
            complete: false,
            hasGraph: false,
            watched: false,
          },
          {
            _id: 'step-0010',
            label: '81002',
            type: 'multi-step',
            progress: 1,
            playState: 'unplayed',
            expanded: false,
            steps: [
              {
                _id: 'step-0010-1',
                label: 'ADTR3PW2',
                type: 'single-step',
                expectedValue: '1',
                actualValue: '1',
                subsystem: 'Attitude',
                complete: false,
                hasGraph: true,
                watched: false,
              },
              {
                _id: 'step-0010-2',
                label: 'GCEGNYOC',
                type: 'single-step',
                expectedValue: 'sel',
                actualValue: 'sel',
                subsystem: 'Attitude',
                complete: false,
                hasGraph: false,
                watched: false,
              },
              {
                _id: 'step-0010-3',
                label: 'P1AESBV1',
                type: 'single-step',
                expectedValue: 'RGP 1',
                actualValue: 'RGP 1',
                subsystem: 'Attitude',
                complete: false,
                hasGraph: false,
                watched: false,
              },
              {
                _id: 'step-0010-4',
                label: 'OMNPTECI',
                type: 'single-step',
                expectedValue: '</=60 Amps',
                actualValue: '59 Amps',
                subsystem: 'Attitude',
                complete: false,
                hasGraph: true,
                watched: false,
              },
            ],
          },
          {
            _id: 'step-0011',
            label: 'ADST1S2IN7',
            type: 'single-step',
            expectedValue: '< 1.25 Magnitude',
            actualValue: '1.23 Magnitude',
            subsystem: 'Attitude',
            complete: false,
            hasGraph: true,
            watched: false,
          },
        ],
      },
      sampleAlerts: {
        type: Array,
        value: [
          {
            errorId: '6d76630e-e99f-5615-9bd8-331a0fc4b955',
            errorSeverity: 'caution',
            errorCategory: 'software',
            errorMessage: 'Red FEP 121 - Offline',
            longMessage: 'Red FEP 121 is offline at 18:37:45',
            errorTime: 1542134265725,
            selected: false,
            new: false,
            expanded: false,
          },
          {
            errorId: '20a96646-abbc-5195-9b20-cff2e99f2ada',
            errorSeverity: 'alert',
            errorCategory: 'spacecraft',
            errorMessage: 'USA-168 - Power degradation',
            longMessage: 'USA-168 suffered power degradation at 18:37:54',
            errorTime: 1542134274738,
            selected: false,
            new: false,
            expanded: false,
          },
          {
            errorId: 'e7d304c2-17ef-5426-ac70-4431fa580409',
            errorSeverity: 'alert',
            errorCategory: 'software',
            errorMessage: 'Black FEP 121 - Degraded',
            longMessage: 'Black FEP 121 is degraded at 18:37:57',
            errorTime: 1542134277742,
            selected: false,
            new: false,
            expanded: false,
          },
          {
            errorId: '47e8c77b-227e-5606-8718-7fafb67f8f8b',
            errorSeverity: 'caution',
            errorCategory: 'spacecraft',
            errorMessage: 'USA-150 - Solar panel misalignment',
            longMessage: 'USA-150 experienced solar panel misalignment at 18:38:00',
            errorTime: 1542134280747,
            selected: false,
            new: false,
            expanded: false,
          },
        ],
      },
      alertsLog: {
        type: Array,
        value: [],
      },
    };
  }

  constructor() {
    super();

    this.menuItems = config.menuItems;
    this.user = config.user;

    this._tooltipListener = this._showTooltip.bind(this);
    this._watchStep = this.watchStep.bind(this);
    this.addEventListener('closeDetailPane', () => this.toggleDetailPane('closed'));
  }

  _startStatusIndicatorsWebsocket() {
    const ws = new WebSocket(config.dataServers.statusIndicators);
    ws.addEventListener('message', (event) => {
      const payload = JSON.parse(event.data);
      this.statusData = payload;
    });
  }

  _startSystemHealthWebsocket() {
    const ws = new WebSocket(config.dataServers.systemHealth);
    ws.addEventListener('message', (event) => {
      const payload = JSON.parse(event.data);
      this.systemHealthData = payload;
    });
  }

  _startAlertsWebsocket() {
    const ws = new WebSocket(config.dataServers.alerts);

    ws.addEventListener('message', (event) => {
      const payload = JSON.parse(event.data);
      payload.selected = false;
      payload.expanded = false;
      payload.new = true;
      const index = this.alertsLog.length;
      this.push('alertsLog', payload);

      setTimeout(() => {
        this.set(['alertsLog', index, 'new'], false);
      }, 2000);
    });

    /* Event Listeners for Alerts */
    this.addEventListener('removeAlerts', this._removeItemsFromAlertsLog);
    this.addEventListener('alertSelectionChanged', this._setAlertSelectedState);
    this.addEventListener('alertToggleChanged', this._setAlertExpandedState);
    this.addEventListener('contactToggleChanged', this._setContactExpandedState);

    /* Populate Alerts with a random sample on load */
    for (let a = 0; a < this.sampleAlerts.length; a++) {
      const alert = this.sampleAlerts[a];
      this.push('alertsLog', alert);
    }
  }

  isAlertVisible(status, category, alert) {
    if (status !== 'all' && status !== alert.errorSeverity) return false;
    if (category !== 'all' && category !== alert.errorCategory) return false;
    return true;
  }

  _removeItemsFromAlertsLog(event) {
    const alertsToKeep = this.alertsLog.filter((alert) => {
      const isVisible = this.isAlertVisible(event.detail.activeStatusFilter, event.detail.activeCategoryFilter, alert);
      // you can stay in the log if you are invisible or not selected
      return !isVisible || !alert.selected;
    });

    this.set('alertsLog', alertsToKeep);
  }

  _setAlertSelectedState(event) {
    this.alertsLog.forEach((alert, index) => {
      if (alert.errorId === event.detail.errorId) {
        this.set(['alertsLog', index, 'selected'], event.detail.setSelectedTo);
        this.notifyPath(['alertsLog', index, 'selected']);
      }
    });
  }

  _setAlertExpandedState(event) {
    this.alertsLog.forEach((alert, index) => {
      if (alert.errorId === event.detail.errorId) {
        this.set(['alertsLog', index, 'expanded'], event.detail.setExpandedTo);
        this.notifyPath(['alertsLog', index, 'expanded']);
      }
    });
  }

  _showTooltip(event) {
    // Cheating a bit here to get the scrollable element the tooltip is housed in
    // there is only one place this is used in the component
    // @todo find a better way to handle scroll
    event.detail.path = event.composedPath();
    if (event.detail.target.getAttribute('data-substep') != null) {
      event.detail.substep = true;
    }
    this.set('tooltipData', event.detail);
  }

  watchStep(event) {
    let target = event.detail.target;
    let substepIndex = -1;

    if (event.detail.substep) {
      substepIndex = parseInt(target.split('-')[2]) - 1;
      target = `${target.split('-')[0]}-${target.split('-')[1]}`;
    }

    const objIndex = this.passPlanSteps.findIndex((step) => {
      return step._id === target;
    });

    if (objIndex >= 0 && substepIndex >= 0) {
      this.notifyPath(`passPlanSteps.${objIndex}.steps.${substepIndex}.watched`, event.detail.value);
    } else if (objIndex >= 0) {
      this.notifyPath(`passPlanSteps.${objIndex}.watched`, event.detail.value);
    }
  }
  appendCommand(e) {
    const newStepId = `step-${(parseInt(this.passPlanSteps.slice(-1)[0]._id.split('-')[1]) + 1)
        .toString()
        .padStart(4, '0')}`;
    const newStep = {
      // create a unique ID
      _id: newStepId,
      label: e.detail.command,
      type: 'multi-step',
      progress: 1,
      playState: 'unplayed',
      expanded: false,
      steps: [
        {
          _id: `${newStepId}-1`,
          type: 'single-step',
          label: 'ADATTERRY', // all commands added by user get the same mnemonic for demo purposes
          expectedValue: '–180.00 to 180.00 Degrees ', // all commands added by user get the same mnemonic for demo purposes
          actualValue: '120.2', // all commands added by user get the same mnemonic for demo purposes
          subsystem: 'Attitude',
          complete: false,
          watched: false,
          hasGraph: true,
        },
      ],
    };
    this.set('passPlanSteps', this.passPlanSteps.concat(newStep));
  }

  displayPopUpMenu(e) {
    if (e.detail.menu) {
      this.appMenuTarget = e.detail.target;
      this.menuItems = e.detail.menu;
    } else {
      this.appMenuTarget = e.currentTarget;
      this.menuItems = config.menuItems;
    }
  }

  connectedCallback() {
    super.connectedCallback();
    this._startStatusIndicatorsWebsocket();
    this._startAlertsWebsocket();
    window.addEventListener('showTooltip', this._tooltipListener);
    window.addEventListener('watch', this._watchStep);
    this.addEventListener('commandAddedFromCommandBar', this.appendCommand);
    this.addEventListener('systemHealthReady', this._startSystemHealthWebsocket);
    this.addEventListener('showPopUpMenu', this.displayPopUpMenu);
  }

  disconnectedCallback() {
    super.disconnectedCallback();

    window.removeEventListener('showTooltip', this._tooltipListener);
    window.removeEventListener('watch', this._watchStep);
    this.removeEventListener('commandAddedFromCommandBar', this.appendCommand);
    this.removeEventListener('systemHealthReady', this._startSystemHealthWebsocket);
    this.removeEventListener('showPopUpMenu', this.displayPopUpMenu);
  }

  ready() {
    super.ready();
  }
}
customElements.define('astro-app', AstroApp);

import {html, PolymerElement} from '@polymer/polymer/polymer-element.js';

import '@polymer/polymer/lib/elements/dom-repeat.js';
import '@polymer/polymer/lib/elements/dom-if.js';
import '@polymer/polymer/lib/elements/array-selector.js';
import '@polymer/polymer/lib/utils/path.js';

/* eslint-disable no-unused-vars */
import {RuxStatus} from '@astrouxds/rux-status/rux-status.js';
import {RuxIcon} from '@astrouxds/rux-icon/rux-icon.js';

import {TtcPrePass} from './pre-pass.js';
import {TtcPassPlan} from './pass-plan.js';
/* eslint-enable no-unused-vars */

import template from './pass.html';
import css from './pass.css';
import stepsCss from './pass-steps.css';

/**
 * @polymer
 * @extends HTMLElement
 */
export class TtcPass extends PolymerElement {
  static get properties() {
    return {
      passState: {
        type: String,
        observer: 'passStateChanged',
      },
      passPlanSteps: {
        type: Array,
        value: () => {
          return [];
        },
      },
    };
  }

  static get template() {
    return html([
      `
        <style include="astro-css">
          ${css}
          ${stepsCss}
        </style> 
        ${template}`,
    ]);
  }

  constructor() {
    super();
  }

  connectedCallback() {
    super.connectedCallback();
  }

  disconnectedCallback() {
    super.disconnectedCallback();
  }

  ready() {
    super.ready();
    this.addEventListener('passStateChanged', this._setPassState);
  }

  passStateChanged(newValue) {
    if (newValue === 'pass') {
      this.dispatchEvent(
          new CustomEvent('systemHealthReady', {
            detail: {},
            bubbles: true,
            composed: true,
          })
      );
    }
  }


  _setPassState(e) {
    this.passState = e.detail.passState;
    if (e.detail.passState === 'interstitial') {
      this.interstitialCountdownSeconds = 2;
      this.interstitialCountdownString = `00:00:0${this.interstitialCountdownSeconds}`;
      this.countdownInterstitial();
    }
  }


  countdownInterstitial() {
    // FYI this only counts down from 59 seconds or less, fake zero-padding and time formatting
    const countdownHours = '00';
    const countdownMinutes = '00';
    let countdownSecondsString = this.interstitialCountdownSeconds;
    setTimeout(() => {
      this.interstitialCountdownSeconds--;
      if (this.interstitialCountdownSeconds < 10) {
        countdownSecondsString = `0${this.interstitialCountdownSeconds}`;
      }
      this.interstitialCountdownString = `${countdownHours}:${countdownMinutes}:${countdownSecondsString}`;
      if (this.interstitialCountdownSeconds >= 0) {
        this.countdownInterstitial();
      } else {
        this.passState = 'pass';
      }
    }, 1000);
  }

  is(a, b) {
    return a === b;
  }
}
customElements.define('ttc-pass', TtcPass);

import {html, PolymerElement} from '@polymer/polymer/polymer-element.js';

import '@polymer/polymer/lib/elements/dom-repeat.js';
import '@polymer/polymer/lib/elements/dom-if.js';
import '@polymer/polymer/lib/elements/array-selector.js';
import '@polymer/polymer/lib/utils/path.js';

/* eslint-disable no-unused-vars */
import {RuxStatus} from '@astrouxds/rux-status/rux-status.js';
import {RuxIcon} from '@astrouxds/rux-icon/rux-icon.js';

/* eslint-enable no-unused-vars */

import template from './subsystems.html';
import css from './subsystems.css';

/**
 * @polymer
 * @extends HTMLElement
 */
export class TtcSubsystems extends PolymerElement {
  static get properties() {
    return {
      attitudeStatus: {
        type: String,
        value: 'off',
      },
      payloadStatus: {
        type: String,
        value: 'off',
      },
      powerStatus: {
        type: String,
        value: 'off',
      },
      propulsionStatus: {
        type: String,
        value: 'off',
      },
      thermalStatus: {
        type: String,
        value: 'off',
      },
      investigateUrl: {
        type: String,
      },
    };
  }

  static get template() {
    return html([
      `
        <style include="astro-css">
          ${css}
        </style> 
        ${template}`,
    ]);
  }

  constructor() {
    super();
  }

  connectedCallback() {
    super.connectedCallback();
  }

  disconnectedCallback() {
    super.disconnectedCallback();
  }

  ready() {
    super.ready();
  }
}

customElements.define('ttc-subsystems', TtcSubsystems);

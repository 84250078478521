import {html, PolymerElement} from '@polymer/polymer/polymer-element.js';

import '@polymer/polymer/lib/elements/dom-repeat.js';
import '@polymer/polymer/lib/elements/dom-if.js';
import '@polymer/polymer/lib/elements/array-selector.js';
import '@polymer/polymer/lib/utils/path.js';

/* eslint-disable no-unused-vars */
import {RuxStatus} from '@astrouxds/rux-status/rux-status.js';
import {RuxIcon} from '@astrouxds/rux-icon/rux-icon.js';
/* eslint-enable no-unused-vars */

import template from './system-health-item.html';
import css from './system-health-item.css';

/**
 * @polymer
 * @extends HTMLElement
 */
export class TtcSystemHealthItem extends PolymerElement {
  static get properties() {
    return {
      status: {
        type: String,
        value: 'off',
      },
      label: {
        type: String,
        value: 'No primary label provided',
      },
      mainValue: {
        type: Number,
        value: '--',
      },
      subLabel: {
        type: String,
        value: 'No secondary label provided',
      },
      subValue: {
        type: Number,
        value: '--',
      },
    };
  }

  static get template() {
    return html([
      `
        <style include="astro-css">
          ${css}
        </style> 
        ${template}`,
    ]);
  }

  constructor() {
    super();
  }

  connectedCallback() {
    super.connectedCallback();
  }

  disconnectedCallback() {
    super.disconnectedCallback();
  }

  ready() {
    super.ready();
  }
}

customElements.define('ttc-system-health-item', TtcSystemHealthItem);

import {html, PolymerElement} from '@polymer/polymer/polymer-element.js';

import '@polymer/polymer/lib/elements/dom-repeat.js';
import '@polymer/polymer/lib/elements/dom-if.js';
import '@polymer/polymer/lib/elements/array-selector.js';
import '@polymer/polymer/lib/utils/path.js';

/* eslint-disable no-unused-vars */
import {RuxStatus} from '@astrouxds/rux-status/rux-status.js';
import {RuxIcon} from '@astrouxds/rux-icon/rux-icon.js';
import {RuxProgress} from '@astrouxds/rux-progress/rux-progress.js';
/* eslint-enable no-unused-vars */

import template from './pass-plan.html';
import css from './pass-plan.css';
import stepsCss from './pass-steps.css';

/**
 * @polymer
 * @extends HTMLElement
 */
export class TtcPassPlan extends PolymerElement {
  static get properties() {
    return {
      steps: {
        type: Array,
        notify: true,
        observer: 'stepsChanged',
        value: function() {
          return [];
        },
      },
    };
  }
  stepsChanged(o, n) {
    if (n) {
      window.requestAnimationFrame(() => {
        this.shadowRoot.querySelector('#scrollEnd').scrollIntoView({behavior: 'smooth'});
      });
    }
  }
  addOne(int) {
    return +int + 1;
  }
  is(a, b) {
    return a === b;
  }
  _expand(e) {
    e.model.set('step.expanded', e.model.step.expanded ? false : true);
    return false;
  }
  toggleCompleteState(e) {
    if (e.model.substep) {
      e.model.set('substep.complete', e.model.substep.complete ? false : true);
    } else {
      e.model.set('step.complete', e.model.step.complete ? false : true);
    }
    e.preventDefault();
    return false;
  }
  toggleStepPlayState(e) {
    const clickedStepModel = e.model;
    if (clickedStepModel.step.playState === 'unplayed' || clickedStepModel.step.playState === 'paused') {
      clickedStepModel.set('step.playState', 'playing');
      this.automateProgressBar(e);
    } else if (clickedStepModel.step.playState === 'playing') {
      clickedStepModel.set('step.playState', 'paused');
    }
  }

  automateProgressBar(e) {
    const clickedStepModel = e.model;
    const progressStepValue = 10;
    if (clickedStepModel.step.progress >= this.stepDuration) {
      clickedStepModel.set('step.playState', 'complete');
      return;
    } else if (clickedStepModel.step.playState === 'playing') {
      const newStepProgress = clickedStepModel.step.progress + progressStepValue;
      clickedStepModel.set('step.progress', newStepProgress);

      setTimeout(this.automateProgressBar.bind(this, e), progressStepValue);
    }
  }

  showToolTip(e) {
    this.dispatchEvent(
        new CustomEvent('showTooltip', {
          detail: {
            target: e.target,
            data: e.model.substep || e.model.step,
          },
          composed: true,
          bubbles: true,
        })
    );
  }

  static get template() {
    return html([
      `
        <style include="astro-css">
          ${css}
          ${stepsCss}
        </style> 
        ${template}`,
    ]);
  }

  constructor() {
    super();
    this.stepDuration = 3000;
  }

  connectedCallback() {
    super.connectedCallback();
  }

  disconnectedCallback() {
    super.disconnectedCallback();
  }

  ready() {
    super.ready();
  }
}

customElements.define('ttc-pass-plan', TtcPassPlan);

import {html, PolymerElement} from '@polymer/polymer/polymer-element.js';
import config from '../../config/config.json';

import '@polymer/polymer/lib/elements/dom-repeat.js';
import '@polymer/polymer/lib/elements/dom-if.js';
import '@polymer/polymer/lib/elements/array-selector.js';
import '@polymer/polymer/lib/utils/path.js';

import commands from '../commands.json';
/* eslint-disable no-unused-vars */
import {RuxStatus} from '@astrouxds/rux-status/rux-status.js';
import {RuxIcon} from '@astrouxds/rux-icon/rux-icon.js';
/* eslint-enable no-unused-vars */
import template from './command.html';
import css from './command.css';

/**
 * @polymer
 * @extends HTMLElement
 */
export class TtcCommand extends PolymerElement {
  static get properties() {
    return {
      quickCommands: {
        type: Array,
        value: function() {
          return [
            {
              'commandString': 'NOOPTYPE',
              'description': 'No Operation, Executive',
              'commandId': 1,
            },
            {
              'commandString': 'CMD_TYPE',
              'description': 'Clear the Command Queue',
              'commandId': 147,
            },
            {
              'commandString': 'WAIT_TYPE',
              'description': 'Wait',
              'commandId': 3,
            },
            {
              'commandString': 'CMD_TYPE',
              'description': 'Pause the Command Queue',
              'commandId': 145,
            },
          ];
        },
      },
      draftCommand: {
        type: String,
        value: '',
        observer: 'draftCommandChanged',
      },
      available: {
        type: Boolean,
        value: false,
      },
      commandInputPlaceholder: {
        type: String,
        computed: 'getCommandInputPlaceholder(available)',
      },
    };
  }

  add(a, b) {
    return +a + +b;
  }

  sortByCommandString(a, b) {
    return a.commandString > b.commandString ? 1 : -1;
  }

  allowSubmit(draftCommand) {
    return this.allCommands.find((command) => command.commandString === draftCommand) ? true : false;
  }

  getCommandInputPlaceholder() {
    return this.available ? 'Start typing to search commands…' : 'Please wait for the pass to begin.';
  }

  _filterCommands(filterValue) {
    // don't run the filter if there is no search param
    if (!filterValue) return null;

    // returns the item if it includes the search term
    // NOTE: using String.prototype.includes which is unavailable
    // in IE11
    return (allCommands) => {
      return allCommands.commandString.toLowerCase().includes(filterValue.toLowerCase()) ||
        allCommands.description.toLowerCase().includes(filterValue.toLowerCase())
        ? true
        : false;
    };
  }

  toggleAllCommands() {
    this.allCommandsOpen = !this.allCommandsOpen;
    this.$.allCommands.focus();
  }
  openAllCommands() {
    this.allCommandsOpen = true;
  }

  selectCommand(e) {
    const selectedCommandModel = e.model.command;
    this.draftCommand = selectedCommandModel.commandString;
    this.$.commandBarSubmit.focus();
    this.allCommandsOpen = false;
    if (this.quickCommands.findIndex((command) => command.commandId == selectedCommandModel.commandId) === -1) {
      this.unshift('quickCommands', selectedCommandModel);
      this.pop('quickCommands');
    }
  }

  addDraftCommandToQueue() {
    this.dispatchEvent(
        new CustomEvent('commandAddedFromCommandBar', {
          detail: {
            command: this.draftCommand,
            user: this.user,
            timestamp: new Date().toLocaleTimeString('en-US', {
              hour: '2-digit',
              minute: '2-digit',
              second: '2-digit',
              hour12: false,
            }),
          },
          bubbles: true,
          composed: true,
        })
    );
    // maybe show a notification in the parent?
    this.draftCommand = '';
    this.allCommandsOpen = false;
  }

  draftCommandChanged() {
    this.allCommandsOpen = true;
  }
  isFocused(elem) {
    return this.shadowRoot.activeElement === elem;
  }
  focusNextTabbable() {
    const currentFocus = this.shadowRoot.activeElement;
    let thisTabIndex = currentFocus.tabIndex;
    this.nextTabbable = null;
    // while we can't find a tabindex that matches
    while (!this.nextTabbable && thisTabIndex < 9000) {
      // increment the tabindex and look again
      this.nextTabbable = this.shadowRoot.querySelector(`[tabindex='${thisTabIndex+1}']`);
      thisTabIndex++;
      if (this.nextTabbable) {
        this.nextTabbable.focus();
      }
    }
  }
  focusPrevTabbable() {
    const currentFocus = this.shadowRoot.activeElement;
    let thisTabIndex = currentFocus.tabIndex;
    this.prevTabbable = null;
    // while we can't find a tabindex that matches (and don't go past this list)
    while (!this.prevTabbable && thisTabIndex > 1002) {
      // decrement the tabindex and look again
      this.prevTabbable = this.shadowRoot.querySelector(`[tabindex='${thisTabIndex-1}']`);
      thisTabIndex--;
      if (this.prevTabbable) {
        this.prevTabbable.focus();
      }
    }
  }


  keyDownHandler(event) {
    const e = event || window.event;

    if (e.keyCode === 27) { // escape closes the window
      this.allCommandsOpen = false;
      return false;
    } else if (e.keyCode === 38) { // up arrow focuses previous item
      this.focusPrevTabbable();
      e.preventDefault();
      return false;
    } else if (e.keyCode === 40) { // down arrow focuses next item
      this.focusNextTabbable();
      e.preventDefault();
      return false;
    } else if (e.keyCode === 32 || e.keyCode === 13) { // space or enter selects currently focused item
      const currentFocus = this.shadowRoot.activeElement;
      const selectedItem =
        this.$.quickCommandsList.itemForElement(currentFocus) ||
        this.$.allCommandsList.itemForElement(currentFocus);
      if (selectedItem) {
        const selectedCommand = {model: {command: selectedItem}};
        this.selectCommand(selectedCommand);
        e.preventDefault();
        return false;
      }
    } else if (e.keyCode === 8) { // backspace returns focus to input
      this.$.commandBarInput.focus();
    }
  }


  listenForExternalClicks(thisPolymerElement) {
    this.addEventListener('click', clickedInternal, false);
    window.addEventListener('click', clickedAnywhere, false);
    let totalClicks = 0;
    function clickedInternal() {
      totalClicks++;
    }
    function clickedAnywhere() {
      totalClicks--;
      if (totalClicks < 0) {
        totalClicks = 0;
        // Clicked outside of current Polymer Element
        thisPolymerElement.allCommandsOpen = false;
      }
    }
  }

  constructor() {
    super();
    this.allCommands = commands;
    this.user = config.user;
  }

  connectedCallback() {
    super.connectedCallback();
  }

  disconnectedCallback() {
    super.disconnectedCallback();
  }

  ready() {
    super.ready();
    this.allCommandsOpen = false;
    this.addEventListener('keydown', this.keyDownHandler, false);
    this.listenForExternalClicks(this);
  }

  static get template() {
    return html([
      `
        <style include="astro-css">
          ${css}
        </style> 
        ${template}`,
    ]);
  }
}

customElements.define('ttc-command', TtcCommand);

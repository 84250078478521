import {html, PolymerElement} from '@polymer/polymer/polymer-element.js';

import '@polymer/polymer/lib/elements/dom-repeat.js';
import '@polymer/polymer/lib/elements/dom-if.js';
import '@polymer/polymer/lib/utils/path.js';

/* eslint-disable no-unused-vars */
import {RuxProgress} from '@astrouxds/rux-progress/rux-progress.js';
/* eslint-enable no-unused-vars */

import template from './pre-pass.html';
import css from './pre-pass.css';
import stepsCss from './pass-steps.css';

/**
 * @polymer
 * @extends HTMLElement
 */
export class TtcPrePass extends PolymerElement {
  static get properties() {
    return {
      steps: {
        type: Array,
        notify: true,
        value: function() {
          return [
            {
              label: 'AIM',
              progress: 0,
            },
            {
              label: 'SARM',
              progress: 0,
            },
            {
              label: 'Lock',
              progress: 0,
            },
            {
              label: 'AOS',
              progress: 0,
            },
            {
              label: 'VCC',
              progress: 0,
            },
            {
              label: 'Pass Plan',
              progress: 0,
            },
          ];
        },
      },
    };
  }
  addOne(int) {
    return +int + 1;
  }

  isComplete(int) {
    return int >= this.stepDuration;
  }
  isInProgress(int) {
    return int > 0 && int < this.stepDuration;
  }

  mapStepProgress(int) {
    if (int === 0) return 'Pending';
    if (int >= this.stepDuration) return 'Complete';
    return 'In Progress';
  }

  notifyFinished() {
    this.dispatchEvent(
        new CustomEvent('passStateChanged', {
          detail: {
            passState: 'interstitial',
          },
          bubbles: true,
          composed: true,
        })
    );
  }

  increaseProgress() {
    const progressStepValue = 10;
    if (this.currentStepIndex >= this.steps.length) {
      this.notifyFinished();
      return;
    }
    const thisStep = this.steps[this.currentStepIndex];
    thisStep.progress += progressStepValue;
    if (thisStep.progress > this.stepDuration) {
      // exit this step
      this.currentStepIndex++;
    } else {
      this.steps[this.currentStepIndex].progress = thisStep.progress;
      this.notifyPath(['steps', this.currentStepIndex, 'progress']);
    }
    setTimeout(this.increaseProgress.bind(this), progressStepValue);
  }

  constructor() {
    super();
    this.stepDuration = 3000;
    this.currentStepIndex = 0;
    this.increaseProgress();
  }

  connectedCallback() {
    super.connectedCallback();
  }

  disconnectedCallback() {
    super.disconnectedCallback();
  }

  ready() {
    super.ready();
  }

  static get template() {
    return html([
      `
        <style include="astro-css">
          ${css}
          ${stepsCss}
        </style> 
        ${template}`,
    ]);
  }
}

customElements.define('ttc-pre-pass', TtcPrePass);

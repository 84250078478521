import {html, PolymerElement} from '@polymer/polymer/polymer-element.js';
import '@polymer/polymer/lib/elements/dom-repeat.js';
import '@polymer/polymer/lib/elements/dom-if.js';
import '@polymer/polymer/lib/elements/array-selector.js';
import '@polymer/polymer/lib/utils/path.js';

/* eslint-disable no-unused-vars */
import {RuxStatus} from '@astrouxds/rux-status/rux-status.js';
import {RuxIcon} from '@astrouxds/rux-icon/rux-icon.js';
/* eslint-enable no-unused-vars */

import template from './alert.html';
import css from './alert.css';

/**
 * @polymer
 * @extends HTMLElement
 */
export class GrmAlert extends PolymerElement {
  static get properties() {
    return {
      selected: {
        type: Boolean,
        value: true,
      },
      new: {
        type: Boolean,
        value: true,
      },
      animationClass: {
        type: String,
        computed: 'computeAnimationClass(new)',
      },
      toggleClass: {
        type: String,
        computed: 'computeToggleClass(expanded)',
      },
      id: {
        type: String,
        value: 'ID missing',
      },
      severity: {
        type: String,
        value: '',
      },
      category: {
        type: String,
        value: '',
      },
      message: {
        type: String,
        value: 'No Error Message',
      },
      detail: {
        type: String,
        value: 'No Error Message',
      },
      time: {
        type: Number,
      },
      timezone: {
        type: String,
        value: 'UTC',
      },
      expanded: {
        type: Boolean,
        value: false,
      },
    };
  }

  static get template() {
    return html([
      `
        <style include="astro-css">
          ${css}
        </style> 
        ${template}`,
    ]);
  }

  constructor() {
    super();
  }

  connectedCallback() {
    super.connectedCallback();
  }

  disconnectedCallback() {
    super.disconnectedCallback();
  }

  ready() {
    super.ready();
  }

  computeAnimationClass() {
    return this.new ? 'animation-active' : '';
  }
  computeToggleClass() {
    return this.expanded ? 'expanded' : 'collapsed';
  }

  notifyClick(e) {
    this.dispatchEvent(
        new CustomEvent('alertSelectionChanged', {
          detail: {
            errorId: this.id,
            setSelectedTo: !this.selected,
          },
          bubbles: true,
          composed: true,
        })
    );
    e.stopPropagation();
    return false;
  }

  toggleExpand() {
    this.dispatchEvent(
        new CustomEvent('alertToggleChanged', {
          detail: {
            errorId: this.id,
            setExpandedTo: !this.expanded,
          },
          bubbles: true,
          composed: true,
        })
    );
  }

  capitalize(val) {
    if (!val) return;
    return val.charAt(0).toUpperCase() + val.substring(1);
  }

  // return an HTML5 datetime string
  _formatMachineTime(timestamp) {
    const time = new Date(timestamp);

    /* eslint-disable max-len */
    const utc = `${time.getUTCFullYear()}-${time.getUTCMonth()}-${time.getUTCDate()} ${time.getUTCHours()}:${time.getUTCMinutes()}:${time.getUTCSeconds()}:${time.getUTCMilliseconds()}`;
    const local = `${time.getFullYear()}-${time.getMonth()}-${time.getDate()} ${time.getHours()}:${time.getMinutes()}:${time.getSeconds()}:${time.getMilliseconds()}`;
    /* eslint-enable max-len */

    return this.timezone.toLowerCase() === 'utc' ? utc : local;
  }

  _formatReadableTime(timestamp) {
    const time = new Date(timestamp);
    return new Date(time).toLocaleTimeString(this.locale, {
      timeZone: this.timezone,
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false,
    });
  }
}

customElements.define('grm-alert', GrmAlert);
